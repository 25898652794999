body {
  background: #64b5f6;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
}

/* file upload */

.upload {
  height: 300px;
  width: 300px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: gray;
  padding: 8px 20px;
  
}

.upload:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

#user-id {
  cursor: pointer;
}

.upload label {
  cursor: pointer;
  border: 2px solid gray;
  margin-top: 20px;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
}

input[type=file] {
  display: none;
}

/* peer selection */

.select-receiver {
  height: 400px;
  width: 300px;
  background: #fff;
  font-family: Roboto;
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.select-receiver:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

.select-receiver p {
  width: 200px;
  padding: 5px;
  cursor: pointer;
  border: 2px solid gray;
  border-radius: 8px;
}

.select-receiver p:hover {
background-color: rgb(219, 219, 219);
}

#cancel-peer-select{
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 10px;
  color: black;
  font-size: larger;
  font-size: 20px;
  font-weight: lighter;
  max-width: fit-content;
}

/* file overview */

.file-overview {
  min-height: 400px;
  width: 300px;
  padding: 30px 0;
  background: #fff;
  font-family: Roboto;
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.file-overview:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}

#cancel-file-view{
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 10px;
  color: black;
  font-size: larger;
  font-size: 20px;
  font-weight: lighter;
  max-width: fit-content;
}

.file-entry {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  border: 2px solid gray;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  margin: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.file-entry p {
  margin: 0;
  margin-bottom: 10px;
  white-space: nowrap; 
  width: 150px; 
  overflow: hidden;
  text-overflow: ellipsis; 
  /* border: 1px solid #000000; */
}
.file-entry progress {
  width: 150px; 
}
.file-entry a {
  margin-top: 10px;
}